import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import Flickity from 'react-flickity-component'

import Styleswrapper from '../../components/StylesWrapper';
import PageMeta from '../../components/blocks/helmet/PageMeta';
import Mainheader from '../../components/MainHeader';
import Footer from '../../components/Footer';
import '../../styles/work/detail.css';
import '../../styles/work/aleyr/index.css';
import '../../styles/plugins/flickity.css'

// images
import AleyrSignature from '../../images/work/logos/aleyr_signature.svg';
import WatermelonIcon from '../../images/icons/watermelon.png';
import SaltIcon from '../../images/icons/salt.png';
import IntroVideo from '../../images/work/aleyr/1.mp4';
import IntroVideoPoster from '../../images/work/aleyr/1.jpg';
import VideoCasetteIcon from '../../images/icons/video_cassette.png';
import ShamrockIcon from '../../images/icons/shamrock.png';
import LadderIcon from '../../images/icons/ladder.png';
import ConfettiBallIcon from '../../images/icons/confetti_ball.png';
import TrumpetIcon from '../../images/icons/trumpet.png';
import DollarBanknoteIcon from '../../images/icons/dollar_banknote.png';
import FurrmarkIcon from '../../images/work/logos/aleyr_furrmark.svg';
import SymbolsVideo from '../../images/work/aleyr/4a.mp4';
import SymbolsVideoPoster from '../../images/work/aleyr/4a.jpg';
import PencilIcon from '../../images/icons/pencil.png';
import WinkingFaceIcon from '../../images/icons/winking_face.png';

import PaymentFlowVideo from '../../images/work/aleyr/payment_flow.mp4';
import PetsFlowVideo from '../../images/work/aleyr/pets_flow.mp4';
import ProductFlowVideo from '../../images/work/aleyr/product_flow.mp4';
import SubscriptionsFlowVideo from '../../images/work/aleyr/subscriptions_flow.mp4';

import CatToy1 from '../../images/work/aleyr/cat_1.png';
import CatToy2 from '../../images/work/aleyr/cat_2.png';
import EyesIcon from '../../images/icons/eyes.png';

import WorkOutro from '../../components/work/WorkOutro';

const Aleyr = () => {
    gsap.registerPlugin(ScrollTrigger);

    const [scrollProgress, setScrollProgress] = useState(0);

    useLayoutEffect(() => {
        let masterTl = gsap.timeline({});

        let horizontalGroupTl = gsap.timeline({});
        horizontalGroupTl.to(".horizontal-group-wrapper", {
            x: "-50%",
            ease: "none",
            scrollTrigger: {
                trigger: ".horizontal-group",
                start: "top top",
                end: "+=200%",
                pin: true,
                anticipatePin: 1,
                pinSpacing: true,
                scrub: 0.5,
            }
        });

        let progress = 0;

        let progressTl = gsap.timeline({});
        progressTl.to(progress, {
            progress: 1,
            duration: 1,
            ease: "none",
            scrollTrigger: {
                start: "top top",
                trigger: ".wd-wrapper",
                endTrigger: outroRef.current,
                end: "top bottom",
                invalidateOnRefresh: true,
                scrub: true,
                onUpdate: (self) => setScrollProgress(self.progress.toFixed(2)),
            }
        });

        masterTl.add(horizontalGroupTl);
        masterTl.add(progressTl);


        // cleanup
        return () => {
            masterTl.kill();
        };
    }, []);

    const workProgressBar = useRef();
    const progressTextSource = "Branding service for a pet care company";
    const progressTextArray = progressTextSource.split("");
    const progressLength = progressTextArray.length;

    const getSplitText = (percentage) => {
        let progress = progressTextArray.slice(0, progressLength * percentage);
        let pending = progressTextArray.slice(progressLength * percentage, progressLength);
        return [progress.join(""), pending.join("")];
    }

    const interfaceCopy = useRef();
    const interfaceCopyWrapperRef = useRef();
    const interfaceCopyBtn = useRef();

    const showInterfaceCopy = () => {
        interfaceCopyBtn.current.classList.add("hidden");
        interfaceCopy.current.classList.remove("hidden");
        interfaceCopy.current.classList.add("visible");
        interfaceCopyWrapperRef.current.scrollBy({
            top: 300,
            left: 0,
            behavior: 'smooth'
        });
    }

    let fadeVariants = {
        initial: {
            opacity: 0,
            y: "2%",
        },
        animate: {
            opacity: 1,
            y: "0%",
            transition: {
                duration: 0.4,
                ease: "circOut",
            }
        }
    };

    const researchRef = useRef();
    const directionRef = useRef();
    const symbolsRef = useRef();
    const interfaceRef = useRef();
    const footnoteRef = useRef();
    const outroRef = useRef();


    // hide progressbar when outro is in view
    useLayoutEffect(() => {
        let animation = gsap.to(workProgressBar.current, {
            opacity: 0,
            ease: "power4.out",
            scrollTrigger: {
                trigger: outroRef.current,
                scrub: 0.25,
                end: "top 90%"
            }
        });

        // cleanup
        return () => {
            animation.kill();
        };
    }, []);


    // gallery
    const galleryRef = useRef(null);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const galleryCaptions = [
        "Payment Flow",
        "Pets Flow",
        "Products Flow",
        "Subscriptions Flow"
    ];
    const galleryOptions = {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: false,
        setGallerySize: false,
        wrapAround: true,
        selectedAttraction: 0.1,
        friction: 0.8,
    };

    // set current gallery index
    useEffect(() => {
        galleryRef.current.on('change', () => {
            setGalleryIndex(galleryRef.current.selectedIndex);
        });

        return () => {
            galleryRef.current.off('change');
            galleryRef.current.destroy();
        }
    }, []);
    
    // pause video playback when not in view
    useEffect(() => {
        let observerOptions = {
            rootMargin: "0px",
            threshold: [0.25, 0.75] // start playing when 25% and 75% of the element is in view
        };

        // watch all selected videos and control playback when they enter the viewport
        let handlePlay = (entries, videoObserver) => {
            entries.forEach((entry) => {
                let videoElement = entry.target;
                if (entry.isIntersecting) {
                    videoElement.play();
                } else {
                    videoElement.pause();
                }
            });
        };

        let videoObserver = new IntersectionObserver(handlePlay, observerOptions);

        // select all the videos on page and observe them
        let videos = document.querySelectorAll("video");
        videos.forEach((video) => {
            videoObserver.observe(video);
        });

        // cleanup
        return () => {
            videoObserver.disconnect();
        }
    }, []);

    return (
        <Styleswrapper
            bgColor1="#2F164D"
            bgColor2="#00070D"
            bg1Stop="0%"
            bg2Stop="35%"
            noise="true"
        >
            <PageMeta
                theme="#2F164D"
                title="Aleyr — Nihar Bhagat"
                description="A case study of the most recent Brand we created"
                image="../images/social/work/aleyr/default.jpg"
                twitterImage="../images/social/work/aleyr/twitter.jpg"
            />

            <Mainheader
                bgColor1="#2F164D"
                bgColor2="#00070D"
                bg1Stop="0%"
                bg2Stop="35%"
                currentPage={'work'}
                noise="true"
            />

            <article className="wd-wrapper aleyr">

                {/* I  N  T  R  O */}
                <section className="wd-block intro">
                    <motion.div
                        className="wd-project-logo"
                        variants={fadeVariants}
                        initial="initial"
                        animate="animate"
                    >
                        <img src={AleyrSignature}
                            alt="Aleyr"
                            className="icon"
                            loading="eager"
                            draggable="false" />
                    </motion.div>

                    <motion.div
                        className="wd-cc"
                        variants={fadeVariants}
                        initial="initial"
                        animate="animate"
                    >
                        <div className="title">
                            <h2>Introduction</h2>
                            <img src={WatermelonIcon}
                                alt="🍉"
                                className="icon"
                                loading="eager"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p className="highlight">
                                Aleyr commissions this project. We get to create a unique case&#8209;study.
                            </p>
                            <span className="divider"></span>
                            <p>
                                Every pet brand is cute and adorable but how sweet is too sweet? And does that work anymore? In this project, I walk in the narrow lane of positioning.
                            </p>
                        </div>
                    </motion.div>

                    <div className="wd-media-wrapper">
                        <motion.div
                            className="wd-media"
                            variants={fadeVariants}
                            initial="initial"
                            animate="animate"
                        >
                            <video
                                draggable="false"
                                loading="eager"
                                className="wd-media-img"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                poster={IntroVideoPoster}
                                title="Motifs of cats and dogs on a purple background"
                                src={IntroVideo} />
                        </motion.div>

                        <motion.div
                            className="wd-cc wd-cc-emoji"
                            initial={{ opacity: 0, y: "-10%", scale: 0.975 }}
                            animate={{
                                opacity: 1,
                                y: "0%",
                                scale: 1,
                                transition: {
                                    duration: 0.35,
                                    ease: "easeOut",
                                    delay: 0.6,
                                }
                            }}
                        >
                            <div className="title">
                                <img src={SaltIcon}
                                    alt="🧂"
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p>
                                    This is one of the last projects at Slangbusters before the pandemic hit us all. Aleyr was the 'ideal client'.
                                    <br />
                                    <br />
                                    Internally, they understood what Branding as a discipline is. And market-wise they were in one of the most saturated ones: the pet care industry.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                </section>


                {/* R  E  S  E  A  R  C  H */}
                <section className="wd-block research" ref={researchRef}>
                    <div className="wd-media-wrapper">
                        <motion.div
                            className="wd-media"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: researchRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <StaticImage
                                src="../../images/work/aleyr/2.jpg"
                                width={1800}
                                alt="Research extracts on a crit-wall for Aleyr"
                                className="wd-media-img"
                                loading="lazy"
                                draggable="false"
                                placeholder="blurred"
                                transformOptions={{
                                    cropFocus: "south",
                                }}
                            />

                            <div className="wd-media-caption">
                                <span>First filter through the literature</span>
                                <img src={ShamrockIcon}
                                    alt="☘️"
                                    loading="lazy"
                                    draggable="false"
                                    className="icon"
                                />
                            </div>
                        </motion.div>

                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView={{
                                y: "0%", opacity: 1, transition: {
                                    delay: 0.1,
                                    duration: 0.6,
                                    ease: "circOut"
                                }
                            }}
                            viewport={{
                                once: true,
                                root: researchRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="title">
                                <h2>Research</h2>
                                <img src={VideoCasetteIcon}
                                    alt="📼"
                                    className="icon"
                                    loading="lazy"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p className="highlight">
                                    Mainstream research was quite archaic
                                </p>
                                <span className="divider"></span>
                                <p>
                                    We went to message boards, YouTube comments sections, Reddit rabbit-holes, to find out the public sentiment for existing pet care brands in USA.
                                    <br /><br />
                                    We saw a market that had gotten used to a clear monopoly.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                </section>

                <div className="horizontal-group">
                    <div className="horizontal-group-wrapper">
                        {/* D  I  R  E  C  T  I  O  N */}
                        <section className="wd-block direction" ref={directionRef}>
                            <div className="wd-media-wrapper">
                                <motion.div
                                    className="wd-cc"
                                    initial={{ scale: 0.95, opacity: 0 }}
                                    whileInView={{
                                        scale: 1,
                                        opacity: 1,
                                        transition: {
                                            duration: 1,
                                            ease: [.77, -0.19, 0, 1.35],
                                        }
                                    }}
                                    viewport={{
                                        once: true,
                                        root: directionRef.current,
                                        margin: "0% 0% -10% 0%",
                                    }}
                                >
                                    <div className="title">
                                        <h2>Strategic Direction</h2>
                                        <img src={LadderIcon}
                                            alt="🪜"
                                            className="icon"
                                            loading="lazy"
                                            draggable="false" />
                                    </div>
                                    <div className="copy">
                                        <p className="highlight">
                                            Finding an empty ladder to be the first upon
                                        </p>
                                        <span className="divider"></span>
                                        <p>
                                            I tried to find a unique positioning. Reorganize. Nothing worked.
                                            <br /><br />
                                            What we default to is heart. From this lens, we saw the division of the market as the ones who see customers as Pet Owners and the other as Pet Parents. We chose to serve the latter ones.
                                        </p>
                                    </div>
                                </motion.div>
                                <motion.div
                                    className="wd-media"
                                    initial={{ x: -20, opacity: 0 }}
                                    whileInView={{
                                        x: 0,
                                        opacity: 1,
                                        transition: {
                                            duration: 0.8,
                                            ease: "circOut",
                                            delay: 0.2,
                                        }
                                    }}
                                    viewport={{
                                        once: true,
                                        root: directionRef.current,
                                        margin: "0% 0% -10% 0%",
                                    }}
                                >
                                    <StaticImage
                                        src="../../images/work/aleyr/3.jpg"
                                        width={1600}
                                        alt="Sticky note held by fingers saying a company that cares for the pets as much as their parents do"
                                        className="wd-media-img"
                                        loading="lazy"
                                        draggable="false"
                                        placeholder="blurred"
                                    />
                                </motion.div>
                                <motion.div
                                    className="wd-cc wd-cc-emoji"
                                    initial={{ x: -20, opacity: 0 }}
                                    whileInView={{
                                        x: 0,
                                        opacity: 1,
                                        transition: {
                                            duration: 0.8,
                                            ease: "circOut",
                                            delay: 0.2,
                                        }
                                    }}
                                    viewport={{
                                        once: true,
                                        root: directionRef.current,
                                        margin: "0% 0% -10% 0%",
                                    }}
                                >
                                    <div className="title">
                                        <img src={ConfettiBallIcon}
                                            alt="🎊"
                                            className="icon"
                                            loading="eager"
                                            draggable="false" />
                                    </div>
                                    <div className="copy">
                                        <p>
                                            The client's enthusiastic response held us on. We formulated this positioning into a strategy and set the plan in motion.
                                            <br />
                                            <br />
                                            The client's enthusiastic response held us on. We formulated this positioning into a strategy and set the plan in motion.
                                        </p>
                                    </div>
                                </motion.div>
                            </div>
                            <motion.div
                                className="creative-direction-block"
                                initial={{ x: -20, opacity: 0 }}
                                whileInView={{
                                    x: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.8,
                                        ease: "circOut",
                                        delay: 0.2,
                                    }
                                }}
                                viewport={{
                                    once: true,
                                    root: directionRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <p className="title">
                                    Creative Direction
                                    <img src={TrumpetIcon}
                                        alt="🎺"
                                        draggable="false"
                                        loading="lazy" />
                                </p>
                                <p className="copy">
                                    Following the advice of Hans Zimmer, I applied the idea of subtext and reflected it throughout the Brand. Every touchpoint depicts a different aspect of the Brand. A different flavor.
                                </p>
                                <p className="connecting-line">
                                    But centrally connected to one
                                    <motion.span
                                        className="line"
                                        initial={{ scaleX: 0 }}
                                        whileInView={{
                                            scaleX: 1,
                                            transition: {
                                                duration: 1,
                                                ease: "circOut",
                                                delay: 0.35,
                                            }
                                        }}
                                        viewport={{
                                            once: true,
                                            root: directionRef.current,
                                            amount: 0.975,
                                        }}
                                    ></motion.span>
                                </p>
                            </motion.div>
                        </section>

                        {/* S  Y  M  B  O  L  S */}
                        <section className="wd-block symbols" ref={symbolsRef}>
                            <motion.div
                                className="wd-cc"
                                initial={{ x: 20, scale: 0.975, opacity: 0 }}
                                whileInView={{
                                    x: 0,
                                    scale: 1,
                                    opacity: 1,
                                    transition: {
                                        duration: 1,
                                        ease: "circOut",
                                    }
                                }}
                                viewport={{
                                    once: true,
                                    amount: 0.2,
                                }}
                            >
                                <div className="title">
                                    <h2>Symbols</h2>
                                    <img src={DollarBanknoteIcon}
                                        alt="💵"
                                        className="icon"
                                        loading="lazy"
                                        draggable="false" />
                                </div>
                                <div className="copy">
                                    <p className="highlight">
                                        Transmission of the feeling I had experienced
                                    </p>
                                    <span className="divider"></span>
                                    <p>
                                        We designed many concepts but they were not exact. They were not looking the part, playing the part.
                                        <br /><br />
                                        As if by a cosmic fluke, I had befriended a stray cat near our apartment. I used to feed her, play with her. Within a few days, she took a place in my heart. I singled out one of the aspects, the warm fuzzy feeling when you place your hand on your pet. The face that your pet has when you do that, is the Furrmark.
                                    </p>
                                </div>
                            </motion.div>

                            <div className="sidenote-wrapper">
                                <div className="wd-media-wrapper">
                                    <motion.div
                                        className="wd-media"
                                        initial={{ x: 20, scale: 0.975, opacity: 0 }}
                                        whileInView={{
                                            x: 0,
                                            scale: 1,
                                            opacity: 1,
                                            transition: {
                                                duration: 1,
                                                ease: "circOut",
                                            }
                                        }}
                                        viewport={{
                                            once: true,
                                            amount: 0.2,
                                        }}
                                    >
                                        <video
                                            draggable="false"
                                            loading="eager"
                                            className="wd-media-img"
                                            autoPlay="autoplay"
                                            playsInline="playsinline"
                                            muted="muted"
                                            poster={SymbolsVideoPoster}
                                            title="Paper sheet used for brandmark exploration containing various versions of marks"
                                            src={SymbolsVideo} />
                                    </motion.div>
                                </div>

                                <motion.div
                                    className="wd-cc sidenote"
                                    initial={{ x: 20, scale: 0.975, opacity: 0 }}
                                    whileInView={{
                                        x: 0,
                                        scale: 1,
                                        opacity: 1,
                                        transition: {
                                            duration: 1,
                                            ease: "circOut",
                                        }
                                    }}
                                    viewport={{
                                        once: true,
                                        amount: 0.2,
                                    }}
                                >
                                    <div className="copy">
                                        <p className="highlight">
                                            Sidebar on Mark performance
                                        </p>
                                        <span className="divider"></span>
                                        <p>
                                            The warmth is conveyed through just 3 strokes. This mark removes our need to create a dog and a cat separately since this is inclusive of both. Here’s where the symbol frees itself from the norm of competitor logos showing dogs in cats, dogs next to cats, cats over dogs.
                                            <br /><br />
                                            <span className="connecting-line-text">This one reflects the feeling.</span>
                                        </p>
                                        <img src={FurrmarkIcon}
                                            alt=""
                                            className="icon"
                                            loading="lazy"
                                            draggable="false" />
                                    </div>
                                </motion.div>

                                <div className="wd-media-wrapper sidenote-art">
                                    <motion.div
                                        className="wd-media"
                                        initial={{ x: 20, scale: 0.975, opacity: 0 }}
                                        whileInView={{
                                            x: 0,
                                            scale: 1,
                                            opacity: 1,
                                            transition: {
                                                duration: 1,
                                                ease: "circOut",
                                            }
                                        }}
                                        viewport={{
                                            once: true,
                                            amount: 0.2,
                                        }}
                                    >
                                        <StaticImage
                                            src="../../images/work/aleyr/4b.jpg"
                                            width={2448}
                                            alt="All logo iterations spread out on the floor of the studio for scale"
                                            className="wd-media-img"
                                            loading="lazy"
                                            draggable="false"
                                            placeholder="blurred"
                                            transformOptions={{
                                                cropFocus: 'south',
                                                fit: "inside"
                                            }}
                                        />
                                        <div className="wd-media-caption">
                                            <span>Room for scale</span>
                                            <img src={PencilIcon}
                                                alt="✏️"
                                                loading="lazy"
                                                draggable="false"
                                                className="icon"
                                            />
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                {/* I  N  T  E  R  F  A  C  E */}
                <motion.section
                    className="wd-block interface"
                    ref={interfaceRef}
                    initial={{ y: 20, opacity: 0 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.4,
                            ease: "circOut",
                        }
                    }}
                    viewport={{
                        once: true,
                        root: interfaceRef.current,
                        margin: "0% 0% -10% 0%",
                    }}
                >
                    <div className="wd-cc" ref={interfaceCopyWrapperRef} >
                        <div className="title">
                            <h2>Interface Design</h2>
                            <img src={DollarBanknoteIcon}
                                alt="💵"
                                className="icon"
                                loading="lazy"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p className="highlight">
                                Too much sweet really kills the mood
                            </p>
                            <span className="divider"></span>
                            <p>
                                When we talk about the different elements depicting different flavours in the same dish, the interface too went through this treatment.
                                <br /><br />
                                We knew that the users of this website would mainly use it to purchase pet supplies and toys. We kept the site’s interface to resemble your child’s lunchbox. Not visually or functionally but emotionally. Look at it, you’ll know what I am talking about. <img src={WinkingFaceIcon} alt="😉" className="icon" draggable="false" loading="lazy" />
                                <br /><br />
                                <button className="read-more-btn" ref={interfaceCopyBtn} onClick={showInterfaceCopy}>Read more</button>
                                <span className="read-more-copy hidden" ref={interfaceCopy}>
                                    We wanted them use this as an instrument to make the purchase swiftly and then hangout in the Content Corner. The corner had carefully crafted help material for any caring pet parent to leaf through.
                                    <br /><br />
                                    All the features were designed only to make the pet parent’s life easy. Our focus was the parent. (And their focus was their pet, hence it was our concern too. A=B. B=C. A=C.)
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="wd-media-wrapper">
                        <div className="flickity-controls">
                            <button className="previous" onClick={() => galleryRef.current.previous()}></button>
                            <button className="next" onClick={() => galleryRef.current.next()}></button>
                        </div>

                        <Flickity
                            flickityRef={flkty => galleryRef.current = flkty}
                            className={'wd-gallery'}
                            options={galleryOptions}
                            static={true}
                        >
                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={PaymentFlowVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={PetsFlowVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={ProductFlowVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={SubscriptionsFlowVideo} />

                        </Flickity>

                        <div className="wd-gallery-caption">
                            <p className="caption-text">
                                {galleryCaptions[galleryIndex]}
                            </p>
                            <div className="divider"></div>
                            <p className="caption-counter">
                                {galleryIndex + 1}/{galleryCaptions.length}
                            </p>
                        </div>
                    </div>
                </motion.section>

                {/* F  O  O  T  N  O  T  E */}
                <section className="wd-block footnote" ref={footnoteRef}>
                    <motion.div
                        className="wd-footnote-card"
                        variants={fadeVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                            once: true,
                            root: footnoteRef.current,
                            margin: "0% 0% -10% 0%",
                        }}
                    >
                        <div className="title">
                            <h2>
                                A real positioning pickle
                                <span className="title-toys">
                                    <img src={CatToy1}
                                        alt=""
                                        draggable="false"
                                        loading="lazy" />
                                </span>
                                <span className="title-toys">
                                    <img src={CatToy2}
                                        alt=""
                                        draggable="false"
                                        loading="lazy" />
                                </span>
                            </h2>
                            <p>
                                This project was a showcase for the craft of converting insight into action and emotions into&nbsp;symbols.
                            </p>
                        </div>
                        <div className="copy">
                            <p>
                                Given the nature of the market Aleyr was in, we were presented with a difficult challenge of market positioning. To solve this, we observed closely and found the division between a pet owner and pet parent to be true. This simple insight changed the way the client looked at their own brand. It freed us from the pressure of making the brand look a certain way. From this inner center, we were able to implement a real sense of playfulness and sincerity in the objects we created, with a little help from H. Zimmer's idea of different flavours for different planes.
                            </p>
                        </div>
                    </motion.div>
                </section>

                <section className="wd-block footnote-secondary">
                    <div className="wd-cc">
                        <div className="title">
                            <h2>Colour</h2>
                            <img src={EyesIcon}
                                alt="👀"
                                className="icon"
                                loading="lazy"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p className="highlight">
                                Direct extensions of playfulness
                            </p>
                            <span className="divider"></span>
                            <p>
                                On the plane of colours, I chose to play a chirpy riff.
                                <br /><br />
                                What is the subject of curiosity for them dogs and cats? Birds! The colours were an extension of this one very specific and not at all vague element:
                                <br /><br />
                                <span className="colored-line">startled eyes of your pet.</span>
                            </p>
                        </div>
                    </div>
                    <div className="wd-media-wrapper">
                        <div className="wd-media">
                            <StaticImage
                                src="../../images/work/aleyr/7.jpg"
                                width={1080}
                                alt="Scarlet tanager sitting on a tree branch"
                                className="wd-media-img"
                                loading="lazy"
                                draggable="false"
                                placeholder="blurred"
                            />
                            <div className="wd-media-caption">
                                <span>Actual image that inspired the&nbsp;colours</span>
                                <span>Not kidding</span>
                            </div>
                        </div>
                    </div>
                </section>

                {/* O  U  T  R  O */}
                <section className="wd-block outro" ref={outroRef}>
                    <WorkOutro type="branding" referrerPath="aleyr" />
                </section>

            </article>

            <div className="wd-progress aleyr" ref={workProgressBar}>
                <p>
                    <span>{getSplitText(scrollProgress)[0]}</span>{getSplitText(scrollProgress)[1]}
                </p>
            </div>

            <Footer
                furtherReadingLinks={true}
            />

        </Styleswrapper>
    );
}

export default Aleyr;
